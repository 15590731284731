<template>
  <v-container
    fluid
  >
    <base-material-card
      class="px-5 py-3 "
      color="indigo"
      icon="mdi-information-variant"
      inline
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="6"
          >
            <span class="text-subtitle-1 font-weight-bold">{{ $t('setting') }}</span>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <v-select
              v-model="statusItem.id"
              :items="statusList"
              :label="$t('NotShowImage')"
              item-text="name"
              item-value="id"
              @change="selectStatus(statusItem.id)"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="6"
          >
            <v-select
              v-model="langItem.id"
              :items="languageList"
              :label="$t('language')"
              item-text="name"
              item-value="id"
              @change="changeLanguage(langItem.name)"
            />
          </v-col>
          <v-col
            v-if="false"
            cols="12"
            md="4"
            sm="6"
          >
            <v-select
              v-model="zbonStatusItem.id"
              :items="statusList"
              :label="$t('UseDailyZbon')"
              item-text="name"
              item-value="id"
              @change="selectZbonStatus(zbonStatusItem.id)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-spacer/>
          <v-btn
            class="mb-2"
            color="primary"
            dark
            @click="summit()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-row>
      </v-container>
    </base-material-card>
  </v-container>
</template>

<script>

  import GlobalSettings, { changeLanguage } from '@/GlobalSettings'

  // const config = require('@/assets/config.json')

  export default {
    data: function () {
      return ({
        statusList: [{
          id: '0',
          name: this.$i18n.t('NO'),
        }, {
          id: '1',
          name: this.$i18n.t('YES'),
        }],
        languageList: [{
          id: '0',
          name: 'ZH',
        }, {
          id: '1',
          name: 'EN',
        }, {
          id: '2',
          name: 'DE',
        }],
        statusItem: {
          id: '',
          name: '',
        },
        zbonStatusItem: {
          id: '',
          name: '',
        },
        langItem: {
          id: '',
          name: '',
        },
      })
    },
    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.statusItem.id = GlobalSettings.isShowOnTable
        this.zbonStatusItem.id = GlobalSettings.UseDailyZbon + 0
        for (const i of this.languageList) {
          if (i.name === this.$i18n.t(localStorage.getItem('lang'))) {
            this.langItem.id = i.id
            this.langItem.name = i.name
          }
        }
      },
      summit () {
        this.$router.go(0)
      },
      selectStatus (i) {
        if (i === '0') {
          localStorage.setItem('isShowOnTable', '0')
        } else {
          localStorage.setItem('isShowOnTable', '1')
        }
      },
      selectZbonStatus (i) {
        if (i === '0') {
          localStorage.setItem('UseDailyZbon', '0')
        } else {
          localStorage.setItem('UseDailyZbon', '1')
        }
      },
      changeLanguage,
    },
  }
</script>
